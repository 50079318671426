var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('div',{staticClass:"ml-2"},[_c('PageTitle',{staticClass:" ft font-weight-bold font-size-md",attrs:{"text":"Opening Stocks For Fuels/Products"}})],1),_c('div',{staticClass:"buttonText ml-1",attrs:{"id":"result"}},[_c('v-chip',{staticClass:"white--text",staticStyle:{"margin-top":"20px","margin-left":"10px"},attrs:{"label":"","color":"primary"}},[_c('span',{staticClass:" ft font-weight-medium font-size-md"},[_vm._v("Opening Stocks For Fuels Per Branch")])])],1),_c('v-row',{staticStyle:{"margin-top":"5px","margin-left":"1px","margin-right":"10px"},attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[[_c('v-card',{staticClass:"elevation-1 pt-5 pb-4",staticStyle:{"border-radius":"8px"}},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{staticClass:"px-3",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.saveOpeningStock)}}},[_c('v-col',[_c('v-row',[_c('div',{staticClass:"d-flex flex-column flex-fill"},[_c('div',{staticClass:"d-flex flex-row flex-grow-1 flex-fill"},[_c('ValidationProvider',{staticClass:"d-flex flex-row",staticStyle:{"flex-basis":"45%"},attrs:{"rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{staticClass:"mx-2 ft font-weight-medium font-size-md",attrs:{"items":_vm.filteredBranches,"outlined":"","loading":_vm.listLoading,"label":"Choose A Branch","hint":"Select from the list of branches to set the opening stock","persistent-hint":"","item-text":"name","error-messages":errors[0],"item-value":"id"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"ft font-weight-medium font-size-sm"},[_vm._v(" "+_vm._s(item.name)+" ")])]}}],null,true),model:{value:(_vm.branchId),callback:function ($$v) {_vm.branchId=$$v},expression:"branchId"}})]}}],null,true)})],1),_c('div',{staticClass:"d-flex flex-column flex-fill pa-4 ma-1",staticStyle:{"background-color":"#eeeeee","border-radius":"6px"}},[(_vm.listLoading)?_c('SkeletonPreloader',{staticClass:"d-flex flex-column my-1",attrs:{"count":6,"type":"list-item-two-line"}}):_vm._l((_vm.stockLineItems),function(row,index){return _c('v-card',{key:index,staticClass:"elevation-0 d-flex flex-column flex-fill mt-2 pt-6 mb-2 px-2",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"d-flex flex-row flex-grow-1"},[_c('ValidationProvider',{staticClass:"d-flex flex-row flex-grow-1 ma-2",attrs:{"tag":"div","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{staticClass:"ft font-weight-medium text-sm",attrs:{"items":_vm.products,"item-text":"name","item-value":"id","prepend-inner-icon":"category","error-messages":errors[0],"outlined":"","readonly":"","label":"Choose A Product","hint":"Select from a list of product "},model:{value:(row.productId),callback:function ($$v) {_vm.$set(row, "productId", $$v)},expression:"row.productId"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"d-flex flex-row flex-grow-1 ma-2",attrs:{"tag":"div","rules":"required|decimals"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"ft font-weight-medium text-sm",attrs:{"outlined":"","prepend-inner-icon":"all_inclusive","label":"Enter Opening Stock","error-messages":errors[0]},model:{value:(row.openingStock),callback:function ($$v) {_vm.$set(row, "openingStock", $$v)},expression:"row.openingStock"}})]}}],null,true)})],1)])})],2)])]),_c('v-row',{staticClass:"mt-5 mr-1"},[_c('div',{staticClass:"d-flex flex-row flex-grow-1 flex-fill justify-end"},[_c('v-btn',{attrs:{"disabled":_vm.isLoading || _vm.filteredBranches.length === 0,"loading":_vm.isLoading,"color":"primary","type":"submit"}},[_vm._v("Save ")])],1)])],1)],1)]}}])})],1)]],2)],1),_c('SnackBar',{attrs:{"showSnackBar":_vm.showSnackBar,"message":_vm.message,"status":_vm.status}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }