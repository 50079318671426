import html2pdf from "html2pdf.js";
import moment from "moment";

export const decimals = {
  validate: (value, { decimals = "*", separator = "." } = {}) => {
    if (value === null || value === undefined || value === "") {
      return {
        valid: false
      };
    }
    if (Number(decimals) === 0) {
      return {
        valid: /^-?\d*$/.test(value)
      };
    }
    const regexPart = decimals === "*" ? "+" : `{1,${decimals}}`;
    const regex = new RegExp(
      `^[-+]?\\d*(\\${separator}\\d${regexPart})?([eE]{1}[-]?\\d+)?$`
    );

    return {
      valid: regex.test(value)
    };
  },
  message: "Invalid decimal values provided"
};

export const PDFGenerator = (container, filename, orientation, format) => {
  let element = document.getElementById(container);
  let opt = {
    margin: 1,
    filename: filename || "downloaded-file.pdf",
    image: {
      type: "jpeg",
      quality: 0.98
    },
    html2canvas: { scale: 2 },
    jsPDF: {
      unit: "cm",
      format: format || "letter",
      orientation: orientation || "portrait"
    }
  };
  html2pdf()
    .from(element)
    .set(opt)
    .save();
};

export const roundAccurately = (number, decimalPlaces) => {
  return Number(
    Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces
  );
};

export const getMonthsBetweenDateRange = (start, end) =>
  Array.from({ length: end.diff(start, "month") + 1 }).map((_, index) => {
    return {
      label: moment(start)
        .add(index, "month")
        .format("MMMM, YYYY"),
      value: moment(start)
        .add(index, "month")
        .format("YYYY-MM-DD")
    };
  });
