<template>
  <v-container>
    <div class="ml-2">
      <PageTitle
        class=" ft font-weight-bold font-size-md"
        text="Opening Stocks For Fuels/Products"
      />
    </div>
    <div class="buttonText ml-1" id="result">
      <v-chip
        label
        color="primary"
        style="margin-top: 20px; margin-left: 10px"
        class="white--text"
      >
        <span class=" ft font-weight-medium font-size-md"
          >Opening Stocks For Fuels Per Branch</span
        >
      </v-chip>
    </div>
    <v-row
      align="center"
      justify="center"
      style="margin-top: 5px; margin-left: 1px; margin-right: 10px"
    >
      <v-col cols="12" sm="12">
        <template>
          <v-card class="elevation-1 pt-5 pb-4" style="border-radius: 8px">
            <ValidationObserver v-slot="{ handleSubmit }">
              <v-form
                @submit.prevent="handleSubmit(saveOpeningStock)"
                class="px-3"
              >
                <v-col>
                  <v-row>
                    <div class="d-flex flex-column flex-fill">
                      <div class="d-flex flex-row flex-grow-1 flex-fill">
                        <ValidationProvider
                          rules="required"
                          class="d-flex flex-row"
                          style="flex-basis: 45%"
                          tag="div"
                          v-slot="{ errors }"
                        >
                          <v-autocomplete
                            :items="filteredBranches"
                            outlined
                            :loading="listLoading"
                            class="mx-2 ft font-weight-medium font-size-md"
                            v-model="branchId"
                            label="Choose A Branch"
                            hint="Select from the list of branches to set the opening stock"
                            persistent-hint
                            item-text="name"
                            :error-messages="errors[0]"
                            item-value="id"
                          >
                            <template #item="{item}">
                              <span class="ft font-weight-medium font-size-sm">
                                {{ item.name }}
                              </span>
                            </template>
                          </v-autocomplete>
                        </ValidationProvider>
                      </div>
                      <div
                        class="d-flex flex-column flex-fill pa-4 ma-1"
                        style="background-color: #eeeeee; border-radius: 6px"
                      >
                        <SkeletonPreloader
                          v-if="listLoading"
                          class="d-flex flex-column my-1"
                          :count="6"
                          type="list-item-two-line"
                        />
                        <template v-else>
                          <v-card
                            v-for="(row, index) in stockLineItems"
                            :key="index"
                            class="elevation-0 d-flex flex-column flex-fill mt-2 pt-6 mb-2 px-2"
                            style="width: 100%"
                          >
                            <div class="d-flex flex-row flex-grow-1">
                              <ValidationProvider
                                tag="div"
                                class="d-flex flex-row flex-grow-1 ma-2"
                                rules="required"
                                v-slot="{ errors }"
                              >
                                <v-autocomplete
                                  :items="products"
                                  v-model="row.productId"
                                  item-text="name"
                                  item-value="id"
                                  prepend-inner-icon="category"
                                  :error-messages="errors[0]"
                                  outlined
                                  readonly
                                  label="Choose A Product"
                                  hint="Select from a list of product "
                                  class="ft font-weight-medium text-sm"
                                />
                              </ValidationProvider>
                              <ValidationProvider
                                tag="div"
                                rules="required|decimals"
                                v-slot="{ errors }"
                                class="d-flex flex-row flex-grow-1 ma-2"
                              >
                                <v-text-field
                                  outlined
                                  v-model="row.openingStock"
                                  prepend-inner-icon="all_inclusive"
                                  label="Enter Opening Stock"
                                  :error-messages="errors[0]"
                                  class="ft font-weight-medium text-sm"
                                />
                              </ValidationProvider>
                            </div>
                          </v-card>
                        </template>
                      </div>
                    </div>
                  </v-row>
                  <v-row class="mt-5 mr-1">
                    <div
                      class="d-flex flex-row flex-grow-1 flex-fill justify-end"
                    >
                      <v-btn
                        :disabled="isLoading || filteredBranches.length === 0"
                        :loading="isLoading"
                        color="primary"
                        type="submit"
                        >Save
                      </v-btn>
                    </div>
                  </v-row>
                </v-col>
              </v-form>
            </ValidationObserver>
          </v-card>
        </template>
      </v-col></v-row
    >

    <SnackBar
      :showSnackBar="showSnackBar"
      :message="message"
      :status="status"
    />
  </v-container>
</template>

<script>
import PageTitle from "../../../../components/PageTitle";
import { timestamp } from "../../../../filters/timestamp";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { numeric, required } from "vee-validate/dist/rules";
import { currencyFormat } from "../../../../filters/currencyFormat";
import { mapGetters } from "vuex";
import { decimals } from "../../../utils/resolvers";
extend("required", {
  ...required,
  message: "Field is required"
});
extend("numeric", {
  ...numeric,
  message: "Field accept only numbers"
});
extend("decimals", decimals);
export default {
  name: "List",
  components: {
    PageTitle,
    ValidationProvider,
    ValidationObserver,
    SnackBar: () => import(`../../../../components/SnackBar.vue`),
    SkeletonPreloader: () => import(`../../../../components/SkeletonPreloader`)
  },
  data() {
    return {
      branchId: "",
      stockLineItems: [],
      isPageLoading: false,
      dialogState: {
        add: false,
        edit: false,
        delete: false
      },
      details: null
    };
  },
  filters: {
    timestamp,
    currencyFormat
  },
  computed: {
    ...mapGetters({
      showSnackBar: "getSnackBarState",
      message: "getSnackBarMessage",
      status: "getSnackBarStatus",
      products: "products/getProductList",
      branches: "branches/getAllBranches",
      listLoading: "getIsListLoading",
      isLoading: "getIsLoading",
      availableStockList: "stocks/getAllProductStock"
    }),
    filteredBranches() {
      return this.branches.filter(
        function(e) {
          return this.indexOf(e.id) < 0;
        },
        [
          ...new Set(
            this.availableStockList.map(stockBranch => stockBranch.branchId.id)
          )
        ]
      );
    }
  },
  mixins: [],
  watch: {
    products(payload) {
      this.stockLineItems = [...payload].map(product => {
        return {
          productId: product.id,
          minimumThreshold: 0,
          openingStock: 0
        };
      });
    }
  },
  methods: {
    async saveOpeningStock() {
      this.$store.dispatch("stocks/addOpeningStockForProducts", {
        branchId: this.branchId,
        stockLineItems: this.stockLineItems
      });
    }
  },
  created() {
    this.$store.dispatch("products/list", { isPaginated: false });
    this.$store.dispatch("branches/list");
    this.$store.dispatch("stocks/products", { isPaginated: false });
  }
};
</script>
